<script setup lang="ts">
import { onMounted } from 'vue'

defineLayout({
  label: 'Infinity Luxury',
})

const isVoixTemplatizer = inject<boolean>('isVoixTemplatizer', false)
const { getCommonLayoutData, playaLayoutMounted, setupHeader } = usePlayaLayout()

if (!isVoixTemplatizer)
  setupHeader()

const {
  currentResort,
} = await getCommonLayoutData(
  isVoixTemplatizer,
  ['resorts', 'brands', 'footerCompanyMenu', 'footerResourcesMenu', 'footerTermsMenu'],
)

onMounted(() => {
  if (!isVoixTemplatizer)
    return
  playaLayoutMounted(currentResort)
})

provide('accentColor', { value: 'rgb(205,170,106)' })
provide('currentResort', currentResort.value.data)
</script>

<template>
  <div>
    <div
      class="text-grey-darker bg-abs-white infinity-luxury-theme"
    >
      <div id="app">
        <div id="portal-destination" transition="fade-transition" />
        <div id="portal-mobile-menu" role="navigation" />

        <div id="playa-content">
          <InfinityLuxuryHeader v-if="!isVoixTemplatizer" :menu="currentResort.data?.menu" />
          <slot />

          <LazyInfinityLuxuryFooter v-if="!isVoixTemplatizer" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  @import '~/assets/css/infinity-luxury.scss';
</style>
